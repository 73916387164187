import {
	postLoginAPI,
	postSocialLoginAPI,
	postSocialRegisterAPI,
} from '@/services/api/auth.api';
import {
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGIN_FAILURE,
	LOGIN_CLEAR_ERROR,
	LOGOUT,
	SET_USERNAME,
	REGISTER_SOCIAL_REQUEST,
	REGISTER_SOCIAL_SUCCESS,
	REGISTER_SOCIAL_FAILURE,
	REGISTER_SOCIAL_CLEAR_ERROR,
} from '../types';
import { transformedUser } from '../../assets/js/transform/user';
import { pathOr } from '../../assets/js/helpers';
import errorMessage from '../../enums/errorMessage';

const defaultUser = {};
const defaultSocialUser = {};

const tokenAtInit = localStorage.getItem('token');

export const getters = {
	getUsername(state) {
		return state.user.username || null;
	},
	transformedUser(state) {
		return transformedUser(state.user);
	},
};

export const mutations = {
	[LOGIN_REQUEST](state) {
		state.isLoading = true;
		state.isLoggedIn = false;
		state.error = false;
		state.errorMessage = null;
	},
	[LOGIN_SUCCESS](state) {
		state.isLoading = false;
		state.isLoggedIn = true;
	},
	[LOGIN_FAILURE](state, message) {
		state.isLoading = false;
		state.isLoggedIn = false;
		state.error = true;
		state.errorMessage = message;
	},

	[LOGOUT](state) {
		state.isLoggedIn = false;
		state.user = defaultUser;
	},

	[LOGIN_CLEAR_ERROR](state) {
		state.error = false;
		state.errorMessage = null;
	},
	[SET_USERNAME](state, username) {
		state.user = {
			...state.user,
			username,
		};
	},
	[REGISTER_SOCIAL_REQUEST](state) {
		state.isLoading = true;
	},
	[REGISTER_SOCIAL_SUCCESS](state) {
		state.isLoading = false;
	},
	[REGISTER_SOCIAL_FAILURE](state, message) {
		state.isLoading = false;
		state.error = true;
		state.errorMessage = message;
	},
	[REGISTER_SOCIAL_CLEAR_ERROR](state) {
		state.error = false;
		state.errorMessage = null;
	},
};

export const actions = {
	async postLogin({ commit, dispatch }, { username = null, password = null }) {
		try {
			commit(LOGIN_REQUEST);

			const { data } = await postLoginAPI({
				username,
				password,
			});

			commit(LOGIN_SUCCESS);

			// Keep token, refreshToken to local storeage
			const { access_token: accessToken = null, refresh_token: refreshToken = null } = data;

			if (accessToken) {
				localStorage.setItem('username', username);
				localStorage.setItem('token', accessToken);

				dispatch('setUsername');
			}
			if (refreshToken) {
				localStorage.setItem('refreshToken', refreshToken);
			}
		} catch (error) {
			const errorStatus = pathOr(null, ['response', 'status'])(error);
			let message;

			switch (errorStatus) {
				case 400:
					message = errorMessage.LOGIN_FAILURE;
					break;

				case 403:
					message = errorMessage.LOGIN_ACCOUNT_INACTIVE;
					break;

				case 429:
					message = errorMessage.LOGIN_TOO_MANY;
					break;

				default:
					message = errorMessage.UNEXPECTED_ERROR;
					break;
			}

			commit(LOGIN_FAILURE, message);
		}
	},
	logout({ commit, dispatch }) {
		commit(LOGOUT);
		dispatch('profile/clearProfile', null, { root: true });
		localStorage.removeItem('token');
		localStorage.removeItem('refreshToken');
		localStorage.removeItem('username');
	},
	clearError({ commit }) {
		commit(LOGIN_CLEAR_ERROR);
	},
	setUsername({ commit }) {
		const username = localStorage.getItem('username');
		commit(SET_USERNAME, username);
	},
	async postSocialLogin({ commit }, { token }) {
		commit(LOGIN_REQUEST);
		const { data } = await postSocialLoginAPI({
			crmToken: token,
		});
		commit(LOGIN_SUCCESS);
		// Keep token, refreshToken to local storeage
		const { access_token: accessToken = null, refresh_token: refreshToken = null } = data;
		if (accessToken) {
			// ?? FYI: in case of social login, username is not available so we move it to after get profile
			// localStorage.setItem('username', username);
			localStorage.setItem('token', accessToken);
			// dispatch('setUsername');
		}
		if (refreshToken) {
			localStorage.setItem('refreshToken', refreshToken);
		}
	},
	async postSocialRegister({ commit }, { token, payload }) {
		try {
			commit(REGISTER_SOCIAL_REQUEST);
			await postSocialRegisterAPI(token, payload);
			commit(REGISTER_SOCIAL_SUCCESS);
		} catch (error) {
			const errorStatus = pathOr(null, ['response', 'status'])(error);
			const apiErrorMessage = pathOr(null, ['response', 'data', 'message'])(error);
			let message;
			switch (errorStatus) {
				case 400:
					message = errorMessage.REGISTER_SOCIAL_FAILURE;
					break;
				case 422:
					message = apiErrorMessage;
					break;
				default:
					message = errorMessage.UNEXPECTED_ERROR;
					break;
			}
			commit(REGISTER_SOCIAL_FAILURE, message);
		}
	},
	clearLoginSocialError({ commit }) {
		commit(LOGIN_FAILURE);
	},
	clearRegisterSocialError({ commit }) {
		commit(REGISTER_SOCIAL_CLEAR_ERROR);
	},
};

export default {
	namespaced: true,
	state: {
		isLoading: false,
		isLoggedIn: !!tokenAtInit,
		error: false,
		errorMessage: null,
		user: defaultUser,
		socialUser: defaultSocialUser,
	},
	getters,
	mutations,
	actions,
};
