export const REGISTER_STATUS = {
	UNVERIFIED: 'unverified',
	REJECTED: 'rejected',
	PENDING: 'pending',
	APPROVED: 'approved',
};

export const SOCIAL_LOGIN_ERROR_TYPE = {
	ACCOUNT_INACTIVE: 'account_inactive',
};

export const PROVIDER = {
	IT_EASY: 'IT_EASY',
};

export const PROVIDER_KEY = {
	[PROVIDER.IT_EASY]: 'iteasy',
};

export const SOCIAL_PROVIDER_GROUP_LIST = [
	{
		provider: PROVIDER_KEY[PROVIDER.IT_EASY],
		icon: {
			url: '/assets/images/icons/icon-it-easy.png',
			alt: 'Icon IT-Easy',
		},
		label: 'Login with IT Easy',
	},
];

export const SOCIAL_PROVIDER_GROUP = {
	[PROVIDER_KEY[PROVIDER.IT_EASY]]: {
		provider: PROVIDER_KEY[PROVIDER.IT_EASY],
		form: { // using in register social form
			header: {
				icon: {
					url: '/assets/images/icons/icon-it-easy.png',
					alt: 'Icon IT-Easy',
				},
				desc: 'Do you have account back office?',
				title: 'Register with IT Easy',
			},
		},
		window: {
			url: `${process.env.VUE_APP_CRM_BASE_URL}/v1/auth/internal/${PROVIDER_KEY[PROVIDER.IT_EASY]}?client_id=${process.env.VUE_APP_CRM_CLIENT_ID}`,
			name: 'iteasy',
		},
	},
};
