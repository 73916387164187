import { refreshTokenAPI, SOCIAL_AUTH_END_POINT } from '../auth.api';
import httpClient from '../httpClient';
import { HTTP_STATUS_CODE } from '../../../enums/http';

// Flags related to refreshToken
const refreshFlags = {
	isRefreshingToken: false,
	refreshTokenPromise: null,
};

// Logout
const logout = () => (window.location = '/logout');

// Refresh token
const refreshToken = async () => {
	// If there're any refresh calls ongoing, wait for it
	if (refreshFlags.isRefreshingToken) {
		return refreshFlags.refreshTokenPromise;
	}

	// Create refresh token fetch call
	refreshFlags.refreshTokenPromise = (async () => {
		const refreshTokenLocalStorage = localStorage.getItem('refreshToken');
		if (!refreshTokenLocalStorage) {
			throw new Error('missing refresh token');
		}
		// Refresh token with CRM
		const { data } = await refreshTokenAPI(refreshTokenLocalStorage);

		localStorage.setItem('refreshToken', data.refresh_token);
		localStorage.setItem('token', data.access_token);
	})();

	// Real refresh token process
	try {
		refreshFlags.isRefreshingToken = true;
		await refreshFlags.refreshTokenPromise;
	} catch (error) {
		logout();
	} finally {
		// eslint-disable-next-line
		refreshFlags.isRefreshingToken = false;
	}

	return null;
};

export const interceptorResponse = (response) => {
	return response;
};

export const interceptorResponseError = async (error) => {
	const { response: { status: statusCode } } = error;
	const xReqRetry = error.config.headers['x-req-retry'];
	let $error = null;

	switch (statusCode) {
		case HTTP_STATUS_CODE.BAD_REQUEST:
			$error = {
				title: 'There was a problem with your request.',
				message: 'Please try again.',
			};
			break;
		case HTTP_STATUS_CODE.UNAUTHORIZED:
			if (xReqRetry >= 1 || refreshFlags.isRefreshingToken) {
				// If retry more than threshold, stop refreshToken and log user out
				// or if got 401 when refresh token, log user out
				// since this request is failed because of another reason
				logout();
			} else {
				// Try refreshing token
				await refreshToken();
				// Count how many times this request try to call the same fetch again
				// eslint-disable-next-line
				error.config.headers['x-req-retry'] = xReqRetry ? xReqRetry + 1 : 1;
				// And call the same fetch again
				return httpClient.request(error.config);
			}

			break;

		case HTTP_STATUS_CODE.FORBIDDEN:
			if (error.response.config.url !== SOCIAL_AUTH_END_POINT) {
				window.location = '/forbidden';
			}

			break;

		case HTTP_STATUS_CODE.NOT_FOUND:
			// redirect to 404 page
			// window.location = '/404';
			break;
		case HTTP_STATUS_CODE.INTERNAL_SERVER_ERROR:
			$error = {
				title: 'There was an unexpected error.',
				message: 'Please try again.',
			};
			break;
		case HTTP_STATUS_CODE.BAD_GATEWAY:
			// redirect to 502 page
			// window.location = "/502";
			break;
		default:
	}

	if ($error) {
		// TODO: Dispatch erorr to store
	}

	return Promise.reject(error);
};
