import httpClient from './httpClient';

export const AUTH_END_POINT = '/oauth/token';
export const PROFILE_API = '/oauth/me';
export const SOCIAL_AUTH_END_POINT = '/backoffice/sessions';
export const SOCIAL_AUTH_REGISTER_END_POINT = '/backoffice/register/social';

export const postLoginAPI = ({ username, password }) => httpClient.post(AUTH_END_POINT, {
	username,
	password,
	scope: '*',
	grant_type: 'password',
	client_id: process.env.VUE_APP_CLIENT_ID,
	client_secret: process.env.VUE_APP_CLIENT_SECRET,
});

export const refreshTokenAPI = (refreshToken) => httpClient.post(AUTH_END_POINT, {
	grant_type: 'refresh_token',
	refresh_token: refreshToken,
	client_id: process.env.VUE_APP_CLIENT_ID,
	client_secret: process.env.VUE_APP_CLIENT_SECRET,
});

export const postSocialLoginAPI = (token) => httpClient.post(SOCIAL_AUTH_END_POINT, {
	crm_token: token.crmToken,
	client_id: process.env.VUE_APP_CRM_BASE_URL,
	client_secret: process.env.VUE_APP_CRM_CLIENT_ID,
});
export const postSocialRegisterAPI = (token, payload) => httpClient.post(SOCIAL_AUTH_REGISTER_END_POINT, {
	crm_token: token,
	first_name: payload.firstname,
	last_name: payload.lastname,
	email: payload.email,
	phone_number: payload.phone,
	is_consent_accepted: payload.isAcceptPolicy,
	// is_subscribe_newsletter: payload.isReceiveUpdates,
	// birthdate: transformBirthdateToAPI(payload.birthdate),
	client_id: process.env.VUE_APP_CRM_BASE_URL,
	client_secret: process.env.VUE_APP_CRM_CLIENT_ID,
});
