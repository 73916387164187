import Vue from 'vue';
import Router from 'vue-router';
import pageTitle from '../enums/pageTitle';
import store from '../store/index';
import { BANNER_TYPE } from '../enums/banners';
import { TYPES as ON_SITE_MESSAGE_TYPES } from '../enums/onSiteMessages';
import { SUB_HEADER_TYPE } from '../enums/subHeader';
import { ROUTE_PERMISSION_KEY } from '../enums/permissions';
import { ROUTE_NAME } from '../enums/route';

const AbandonedCarts = () => import('@/views/AbandonedCarts');
const AttributeKeys = () => import('@/views/AttributeKeys');
const AttributeSetEdit = () => import('@/views/AttributeSetEdit');
const AttributeSets = () => import('@/views/AttributeSets');
const BannerHeroCreate = () => import('@/views/BannerHeroCreate');
const BannerHeroEdit = () => import('@/views/BannerHeroEdit');
const BannerHighlightCreate = () => import('@/views/BannerHighlightCreate');
const BannerHighlightEdit = () => import('@/views/BannerHighlightEdit');
const BannerShortcutCreate = () => import('@/views/BannerShortcutCreate');
const BannerSmallCreate = () => import('@/views/BannerSmallCreate');
const BannerSmallEdit = () => import('@/views/BannerSmallEdit');
const BannerTextCreate = () => import('@/views/BannerTextCreate');
const BannerTextEdit = () => import('@/views/BannerTextEdit');
const BannerPictureThumbnailCreate = () => import('@/views/BannerPictureThumbnailCreate');
const BannerPictureThumbnailEdit = () => import('@/views/BannerPictureThumbnailEdit');
const BackInStockNotification = () => import('@/views/BackInStockNotification');
const Categories = () => import('@/views/Categories');
const CategoryAttributeEdit = () => import('@/views/CategoryAttributeEdit');
const CategoryCreate = () => import('@/views/CategoryCreate');
const CategoryEdit = () => import('@/views/CategoryEdit');
const CustomerList = () => import('@/views/CustomerList');
const CustomerCreate = () => import('@/views/CustomerCreate');
const CustomerGroupCreate = () => import('@/views/CustomerGroupCreate');
const CustomerEdit = () => import('@/views/CustomerEdit');
const CustomerGroupList = () => import('@/views/CustomerGroupList');
const CustomerGroupEdit = () => import('@/views/CustomerGroupEdit');
const Homepage = () => import('@/views/Homepage');
const InstallmentPlanChannel = () => import('@/views/InstallmentPlanChannel');
const InstallmentPlanCreate = () => import('@/views/InstallmentPlanCreate');
const InstallmentPlanChannelCreate = () => import('@/views/InstallmentPlanChannelCreate');
const InstallmentPlanEdit = () => import('@/views/InstallmentPlanEdit');
const InstallmentPlanChannelEdit = () => import('@/views/InstallmentPlanChannelEdit');
const Login = () => import('@/views/Login');
const Logout = () => import('@/views/Logout');
const MarketingCampaignCreate = () => import('@/views/MarketingCampaignCreate');
const MarketingCampaignList = () => import('@/views/MarketingCampaignList');
const MarketingCampaignEdit = () => import('@/views/MarketingCampaignEdit');
const MarketingCampaignAttributeEdit = () => import('@/views/MarketingCampaignAttributeEdit');
const MicrositeWidgets = () => import('@/views/MicrositeWidgets');
const OrderDetail = () => import('@/views/OrderDetail');
const Orders = () => import('@/views/Orders');
const PageCreate = () => import('@/views/PageCreate');
const PageEdit = () => import('@/views/PageEdit');
const PageServerError = () => import('@/views/PageServerError');
const PageForbidden = () => import('@/views/PageForbidden');
const PageNotFound = () => import('@/views/PageNotFound');
const Pages = () => import('@/views/Pages');
const Payments = () => import('@/views/Payments');
const PaymentSetting = () => import('@/views/PaymentSetting');
const PaymentSubgroupsChannelEdit = () => import('@/views/PaymentSubgroupsChannelEdit');
const PaymentCreditCardBankChannelCreate = () => import('@/views/PaymentCreditCardBankChannelCreate');
const PaymentCreditCardBankChannelEdit = () => import('@/views/PaymentCreditCardBankChannelEdit');
const PreOrders = () => import('@/views/PreOrders');
const PreOrderCreate = () => import('@/views/PreOrderCreate');
const PreOrderEdit = () => import('@/views/PreOrderEdit');
const PreOrderPickupAtStoreCreate = () => import('@/views/PreOrderPickupAtStoreCreate');
const PreOrderPickupAtStoreEdit = () => import('@/views/PreOrderPickupAtStoreEdit');
const ProductEdit = () => import('@/views/ProductEdit');
const ProductImportHistory = () => import('@/views/ProductImportHistory');
const ProductLabelCreate = () => import('@/views/ProductLabelCreate');
const ProductLabelEdit = () => import('@/views/ProductLabelEdit');
const ProductLabelBulkAttach = () => import('@/views/ProductLabelBulkAttach');
const ProductLabels = () => import('@/views/ProductLabels');
const Products = () => import('@/views/Products');
const Promotions = () => import('@/views/Promotions');
const PromotionCreate = () => import('@/views/PromotionCreate');
const PromotionEdit = () => import('@/views/PromotionEdit');
const SearchSynonyms = () => import('@/views/SearchSynonyms');
const SearchWeightCreate = () => import('@/views/SearchWeightCreate');
const SearchWeightEdit = () => import('@/views/SearchWeightEdit');
const SearchWeights = () => import('@/views/SearchWeights');
const ShipmentDetail = () => import('@/views/ShipmentDetail');
const Shipments = () => import('@/views/Shipments');
const ShippingCategoryAdditionalFeeCreate = () => import('@/views/ShippingCategoryAdditionalFeeCreate');
const ShippingCategoryAdditionalFeeEdit = () => import('@/views/ShippingCategoryAdditionalFeeEdit');
const ShippingPostcodeAdditionalFeeCreate = () => import('@/views/ShippingPostcodeAdditionalFeeCreate');
const ShippingPostcodeAdditionalFeeEdit = () => import('@/views/ShippingPostcodeAdditionalFeeEdit');
const ShippingProductAdditionalFeeCreate = () => import('@/views/ShippingProductAdditionalFeeCreate');
const ShippingProductAdditionalFeeEdit = () => import('@/views/ShippingProductAdditionalFeeEdit');
const ShippingRateCreate = () => import('@/views/ShippingRateCreate');
const ShippingRateEdit = () => import('@/views/ShippingRateEdit');
const ShippingSetting = () => import('@/views/ShippingSetting');
const BrandInstallmentPlanCreate = () => import('@/views/BrandInstallmentPlanCreate');
const BrandInstallmentPlanEdit = () => import('@/views/BrandInstallmentPlanEdit');
const BrandInstallmentPlanConditionCreate = () => import('@/views/BrandInstallmentPlanConditionCreate');
const BrandInstallmentPlanConditionEdit = () => import('@/views/BrandInstallmentPlanConditionEdit');
const KTCForeverPlanCreate = () => import('@/views/KTCForeverPlanCreate');
const KTCForeverPlanEdit = () => import('@/views/KTCForeverPlanEdit');
const KTCInstallmentPlanCreate = () => import('@/views/KTCInstallmentPlanCreate');
const KTCInstallmentPlanEdit = () => import('@/views/KTCInstallmentPlanEdit');
const TheContainer = () => import('@/containers/TheContainer');
const UserCreate = () => import('@/views/UserCreate');
const UserEdit = () => import('@/views/UserEdit');
const UserProfile = () => import('@/views/UserProfile');
const UserRole = () => import('@/views/UserRole');
const UserRoleCreate = () => import('@/views/UserRoleCreate');
const UserRoleEdit = () => import('@/views/UserRoleEdit');
const Users = () => import('@/views/Users');
const WarehouseCreate = () => import('@/views/WarehouseCreate');
const WarehouseEdit = () => import('@/views/WarehouseEdit');
const WarehouseList = () => import('@/views/WarehouseList');
const ProductFeedCreate = () => import('@/views/ProductFeedCreate');
const ProductFeedList = () => import('@/views/ProductFeedList');
const ProductFeedEdit = () => import('@/views/ProductFeedEdit');
const FlashSales = () => import('@/views/FlashSales');
const FlashSaleCreate = () => import('@/views/FlashSaleCreate');
const FlashSaleEdit = () => import('@/views/FlashSaleEdit');
const AuditLogs = () => import('@/views/AuditLogs');
const AuditLogDetail = () => import('@/views/AuditLogDetail');
const BundleSetList = () => import('@/views/BundleSetList');
const BundleSetCreate = () => import('@/views/BundleSetCreate');
const BundleSetEdit = () => import('@/views/BundleSetEdit');
const OnSiteMessageList = () => import('@/views/OnSiteMessageList');
const OnSiteMessagePopupCreate = () => import('@/views/OnSiteMessagePopupCreate');
const OnSiteMessagePopupEdit = () => import('@/views/OnSiteMessagePopupEdit');
const OnSiteMessageStripCreate = () => import('@/views/OnSiteMessageStripCreate');
const OnSiteMessageStripEdit = () => import('@/views/OnSiteMessageStripEdit');
const KTCInstallmentPlanList = () => import('@/views/KTCInstallmentPlanList');
const LimitProductList = () => import('@/views/limitProduct/LimitProductList.vue');
const LimitProductCreate = () => import('@/views/limitProduct/LimitProductCreate.vue');
const LimitProductEdit = () => import('@/views/limitProduct/LimitProductEdit.vue');
const Index = () => import('@/views/Index');
const SubHeaders = () => import('@/views/SubHeaders');
const SearchResultCache = () => import('@/views/SearchResultCache');
const OrderAutoComplete = () => import('@/views/sale/orderAutoComplete');
// const SubHeaderCart = () => import('@/views/SubHeaderCart');
const SocialRegister = () => import('@/views/SocialRegister.vue');

Vue.use(Router);

const router = new Router({
	mode: 'history', // https://router.vuejs.org/api/#mode
	linkActiveClass: 'open active',
	scrollBehavior: () => ({ y: 0 }),
	routes: [
		{
			path: '/login',
			name: ROUTE_NAME.LOGIN,
			component: Login,
			meta: {
				requireAuth: false,
			},
		},
		{
			path: '/register/social',
			name: ROUTE_NAME.REGISTER_SOCIAL,
			component: SocialRegister,
			meta: {
				requireAuth: false,
			},
		},
		{
			path: '/logout',
			name: ROUTE_NAME.LOGOUT,
			component: Logout,
			meta: {
				requireAuth: false,
			},
		},
		{
			path: '/',
			component: TheContainer,
			children: [
				{
					path: '',
					name: ROUTE_NAME.INDEX,
					component: Index,
				},
				{
					path: 'users',
					redirect: '/users',
					name: ROUTE_NAME.USERS,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						{
							path: '/',
							name: ROUTE_NAME.USER_LISTS,
							component: Users,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.USER_MANAGEMENT.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.USER,
							},
						},
						{
							path: 'profile',
							name: ROUTE_NAME.USER_PROFILE,
							component: UserProfile,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.USER_MANAGEMENT.PROFILE,
							},
						},
						{
							path: 'create',
							name: ROUTE_NAME.USER_CREATE,
							component: UserCreate,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.USER_MANAGEMENT.CREATE_NEW_USER,
								routePermissionKey: ROUTE_PERMISSION_KEY.USER,
							},
						},
						{
							path: 'roles',
							name: ROUTE_NAME.USER_ROLE,
							component: UserRole,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.USER_MANAGEMENT.ROLE,
								routePermissionKey: ROUTE_PERMISSION_KEY.ROLE_PERMISSION,
							},
						},
						{
							path: 'roles/create',
							name: ROUTE_NAME.USER_ROLE_CREATE,
							component: UserRoleCreate,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.USER_MANAGEMENT.CREATE_ROLE,
								routePermissionKey: ROUTE_PERMISSION_KEY.ROLE_PERMISSION,
							},
						},
						{
							path: 'roles/:id',
							name: ROUTE_NAME.USER_ROLE_EDIT,
							component: UserRoleEdit,
							meta: {
								requireAuth: true,
								breadcrumbs: [
									{ title: 'Role & permission', route: { name: ROUTE_NAME.USER_ROLE } },
								],
								pageTitle: pageTitle.USER_MANAGEMENT.ROLE_EDIT,
								routePermissionKey: ROUTE_PERMISSION_KEY.ROLE_PERMISSION,
							},
						},
						{
							path: ':id',
							name: ROUTE_NAME.USER_EDIT,
							component: UserEdit,
							meta: {
								requireAuth: true,
								breadcrumbs: [
									{ title: 'User Management', route: { name: ROUTE_NAME.USER_LISTS } },
								],
								routePermissionKey: ROUTE_PERMISSION_KEY.USER,
							},
						},
					],
					meta: {
						requireAuth: true,
					},
				},
				{
					path: 'categories',
					redirect: '/categories',
					name: ROUTE_NAME.CATEGORIES,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						{
							path: '/',
							name: ROUTE_NAME.CATEGORY_LISTS,
							component: Categories,
							meta: {
								requireAuth: true,
								showBreadcrumb: false,
								pageTitle: pageTitle.CATEGORY.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.CATEGORY,
							},
						},
						{
							path: 'create',
							name: ROUTE_NAME.CREATE_NEW_CATEGORY,
							component: CategoryCreate,
							meta: {
								requireAuth: true,
								showBreadcrumb: false,
								pageTitle: pageTitle.CATEGORY.CREATE,
								routePermissionKey: ROUTE_PERMISSION_KEY.CATEGORY,
							},
						},
						{
							path: ':id',
							name: ROUTE_NAME.CATEGORY_EDIT,
							component: CategoryEdit,
							meta: {
								requireAuth: true,
								breadcrumbs: [
									{ title: pageTitle.CATEGORY.LIST, route: { name: ROUTE_NAME.CATEGORY_LISTS } },
								],
								routePermissionKey: ROUTE_PERMISSION_KEY.CATEGORY,
							},
						},
						{
							path: ':id/attribute-key',
							name: ROUTE_NAME.CATEGORY_ATTRIBUTE_EDIT,
							component: CategoryAttributeEdit,
							meta: {
								requireAuth: true,
								pageTitle: pageTitle.CATEGORY.ATTRIBUTE_EDIT,
								breadcrumbs: [
									{ title: pageTitle.CATEGORY.LIST, route: { name: ROUTE_NAME.CATEGORY_LISTS } },
								],
								routePermissionKey: ROUTE_PERMISSION_KEY.CATEGORY,
							},
						},
					],
					meta: {
						requireAuth: true,
					},
				},
				// Products
				{
					path: 'products',
					redirect: '/products',
					name: ROUTE_NAME.PRODUCTS,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						{
							path: '/',
							name: ROUTE_NAME.PRODUCTS_LISTS,
							component: Products,
							meta: {
								requireAuth: true,
								showBreadcrumb: false,
								pageTitle: pageTitle.PRODUCT.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.PRODUCT,
							},
						},
						{
							path: ':id',
							name: ROUTE_NAME.PRODUCT_EDIT,
							component: ProductEdit,
							meta: {
								requireAuth: true,
								pageTitle: pageTitle.PRODUCT.EDIT,
								breadcrumbs: [
									{ title: pageTitle.PRODUCT.LIST, route: { name: ROUTE_NAME.PRODUCTS_LISTS } },
								],
								routePermissionKey: ROUTE_PERMISSION_KEY.PRODUCT,
							},
						},
						{
							path: 'import/history',
							name: ROUTE_NAME.PRODUCT_IMPORT_HISTORY,
							component: ProductImportHistory,
							meta: {
								requireAuth: true,
								showBreadcrumb: false,
								pageTitle: pageTitle.PRODUCT.IMPORT_HISTORY,
								routePermissionKey: ROUTE_PERMISSION_KEY.IMPORT_HISTORY,
							},
						},
					],
					meta: {
						requireAuth: true,
					},
				},
				// Attribute Sets
				{
					path: '/attribute-sets',
					redirect: '/attribute-sets',
					name: ROUTE_NAME.ATTRIBUTE_SET,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						{
							path: '',
							name: ROUTE_NAME.ATTRIBUTE_SET_LIST,
							component: AttributeSets,
							meta: {
								requireAuth: true,
								showBreadcrumb: false,
								pageTitle: pageTitle.ATTRIBUTE.SET,
								routePermissionKey: ROUTE_PERMISSION_KEY.ATTRIBUTE_SET,
							},
						},
						{
							path: ':id',
							name: ROUTE_NAME.ATTRIBUTE_SET_EDIT,
							component: AttributeSetEdit,
							meta: {
								requireAuth: true,
								breadcrumbs: [
									{ title: 'Attribute sets', route: { name: ROUTE_NAME.ATTRIBUTE_SET } },
								],
								routePermissionKey: ROUTE_PERMISSION_KEY.ATTRIBUTE_SET,
							},
						},
					],
				},
				// Attribute Keys
				{
					path: '/attribute-keys',
					redirect: '/attribute-keys',
					name: ROUTE_NAME.ATTRIBUTE_KEY,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						{
							path: '',
							name: ROUTE_NAME.ATTRIBUTE_KEY_LIST,
							component: AttributeKeys,
							meta: {
								requireAuth: true,
								showBreadcrumb: false,
								pageTitle: pageTitle.ATTRIBUTE.KEY,
								routePermissionKey: ROUTE_PERMISSION_KEY.ATTRIBUTE_KEY,
							},
						},
					],
				},
				// Product Label
				{
					path: '/labels',
					redirect: '/labels',
					name: ROUTE_NAME.PRODUCT_LABELS,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						{
							path: '/',
							name: ROUTE_NAME.PRODUCT_LABEL_LIST,
							component: ProductLabels,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.PRODUCT_LABEL.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.PRODUCT_LABEL,
							},
						},
						{
							path: 'create',
							name: ROUTE_NAME.PRODUCT_LABEL_CREATE,
							component: ProductLabelCreate,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.PRODUCT_LABEL.CREATE,
								routePermissionKey: ROUTE_PERMISSION_KEY.PRODUCT_LABEL,
							},
						},
						{
							path: 'bulk-attach',
							name: ROUTE_NAME.PRODUCT_LABEL_BULK_ATTACH,
							component: ProductLabelBulkAttach,
							meta: {
								requireAuth: true,
								breadcrumbs: [
									{ title: 'Product label', route: { name: ROUTE_NAME.PRODUCT_LABEL_LIST } },
								],
								routePermissionKey: ROUTE_PERMISSION_KEY.PRODUCT_LABEL,
							},
						},
						{
							path: ':id',
							name: ROUTE_NAME.PRODUCT_LABEL_EDIT,
							component: ProductLabelEdit,
							meta: {
								requireAuth: true,
								breadcrumbs: [
									{ title: 'Product label', route: { name: ROUTE_NAME.PRODUCT_LABEL_LIST } },
								],
								routePermissionKey: ROUTE_PERMISSION_KEY.PRODUCT_LABEL,
							},
						},
					],
				},
				// Payment
				{
					path: '/payments',
					redirect: '/payments',
					name: ROUTE_NAME.PAYMENTS,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						{
							path: '/',
							name: ROUTE_NAME.PAYMENT_LIST,
							component: Payments,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.PAYMENTS.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.PAYMENT,
							},
						},
						{
							path: 'setting/:id',
							name: ROUTE_NAME.PAYMENT_SETTING,
							component: PaymentSetting,
							meta: {
								requireAuth: true,
								breadcrumbs: [
									{ title: 'Payment Settings', route: { name: ROUTE_NAME.PAYMENT_LIST } },
								],
								routePermissionKey: ROUTE_PERMISSION_KEY.PAYMENT,
							},
						},
						{
							path: 'setting/:id/method/:methodId',
							name: ROUTE_NAME.PAYMENT_METHOD_SETTING,
							component: PaymentSetting,
							meta: {
								requireAuth: true,
								breadcrumbs: [
									{ title: 'Payment Settings', route: { name: ROUTE_NAME.PAYMENT_LIST } },
								],
								routePermissionKey: ROUTE_PERMISSION_KEY.PAYMENT,
							},
						},
						{
							path: 'setting/:id/method/:methodId/installment-plans/create',
							name: ROUTE_NAME.INSTALLMENT_PLAN_CREATE,
							component: InstallmentPlanCreate,
							meta: {
								requireAuth: true,
								routePermissionKey: ROUTE_PERMISSION_KEY.PAYMENT,
							},
						},
						{
							path: 'setting/:id/method/:methodId/channels/:channelId',
							name: ROUTE_NAME.INSTALLMENT_PLAN_CHANNEL,
							component: InstallmentPlanChannel,
							meta: {
								pageTitle: pageTitle.INSTALLMENT_PLAN.CHANNEL,
								requireAuth: true,
								routePermissionKey: ROUTE_PERMISSION_KEY.PAYMENT,
							},
						},
						{
							path: 'setting/:id/method/:methodId/channels/:channelId/installment-plans/create',
							name: ROUTE_NAME.INSTALLMENT_PLAN_CHANNEL_CREATE,
							component: InstallmentPlanChannelCreate,
							meta: {
								requireAuth: true,
								routePermissionKey: ROUTE_PERMISSION_KEY.PAYMENT,
							},
						},
						{
							path: 'setting/:id/method/:methodId/installment-plans/:planId',
							name: ROUTE_NAME.INSTALLMENT_PLAN_EDIT,
							component: InstallmentPlanEdit,
							meta: {
								pageTitle: pageTitle.INSTALLMENT_PLAN.EDIT,
								requireAuth: true,
								routePermissionKey: ROUTE_PERMISSION_KEY.PAYMENT,
							},
						},
						{
							path: 'setting/:id/method/:methodId/channels/:channelId/installment-plans/:planId',
							name: ROUTE_NAME.INSTALLMENT_PLAN_CHANNEL_EDIT,
							component: InstallmentPlanChannelEdit,
							meta: {
								pageTitle: pageTitle.INSTALLMENT_PLAN.EDIT,
								requireAuth: true,
								routePermissionKey: ROUTE_PERMISSION_KEY.PAYMENT,
							},
						},
						// Brand plan - Specific SKU
						{
							path: 'setting/:id/method/:methodId/brand-installment-plans/create',
							name: 'BrandInstallmentPlanCreate',
							component: BrandInstallmentPlanCreate,
							meta: {
								pageTitle: pageTitle.INSTALLMENT_PLAN.CREATE_CAMPAIGN,
								requireAuth: true,
								routePermissionKey: ROUTE_PERMISSION_KEY.PAYMENT,
							},
						},
						{
							path: 'setting/:id/method/:methodId/brand-installment-plans/:brandId',
							name: 'BrandInstallmentPlanEdit',
							component: BrandInstallmentPlanEdit,
							meta: {
								pageTitle: pageTitle.INSTALLMENT_PLAN.EDIT_CAMPAIGN,
								requireAuth: true,
								routePermissionKey: ROUTE_PERMISSION_KEY.PAYMENT,
							},
						},
						{
							path: 'setting/:id/method/:methodId/brand-installment-plans/:brandId/installment-plans/create',
							name: 'BrandInstallmentPlanConditionCreate',
							component: BrandInstallmentPlanConditionCreate,
							meta: {
								pageTitle: pageTitle.INSTALLMENT_PLAN.CREATE,
								requireAuth: true,
								routePermissionKey: ROUTE_PERMISSION_KEY.PAYMENT,
							},
						},
						{
							path: 'setting/:id/method/:methodId/brand-installment-plans/:brandId/installment-plans/:planId',
							name: 'BrandInstallmentPlanConditionEdit',
							component: BrandInstallmentPlanConditionEdit,
							meta: {
								pageTitle: pageTitle.INSTALLMENT_PLAN.EDIT,
								requireAuth: true,
								routePermissionKey: ROUTE_PERMISSION_KEY.PAYMENT,
							},
						},
						// Merchant and supplier installment plan - KTC bank
						{
							path: 'setting/:id/method/:methodId/merchant-and-supplier-installment-plans',
							name: ROUTE_NAME.KTC_INSTALLMENT_PLAN_LIST,
							component: KTCInstallmentPlanList,
							meta: {
								pageTitle: pageTitle.INSTALLMENT_PLAN.MERCHANT_SUPPLIER_LIST,
								requireAuth: true,
								routePermissionKey: ROUTE_PERMISSION_KEY.PAYMENT,
							},
						},
						{
							path: 'setting/:id/method/:methodId/merchant-and-supplier-installment-plans/create',
							name: 'KTCInstallmentPlanCreate',
							component: KTCInstallmentPlanCreate,
							meta: {
								pageTitle: pageTitle.INSTALLMENT_PLAN.CREATE_MERCHANT_SUPPLIER,
								requireAuth: true,
								routePermissionKey: ROUTE_PERMISSION_KEY.PAYMENT,
							},
						},
						{
							path: 'setting/:id/method/:methodId/merchant-and-supplier-installment-plans/:planId',
							name: 'KTCInstallmentPlanEdit',
							component: KTCInstallmentPlanEdit,
							meta: {
								pageTitle: pageTitle.INSTALLMENT_PLAN.EDIT_MERCHANT_SUPPLIER,
								requireAuth: true,
								routePermissionKey: ROUTE_PERMISSION_KEY.PAYMENT,
							},
						},
						// Forever plan - KTC bank
						{
							path: 'setting/:id/method/:methodId/forever-plans/create',
							name: 'KTCForeverPlanCreate',
							component: KTCForeverPlanCreate,
							meta: {
								pageTitle: pageTitle.FOREVER_PLAN.CREATE_FOREVER,
								requireAuth: true,
								routePermissionKey: ROUTE_PERMISSION_KEY.PAYMENT,
							},
						},
						{
							path: 'setting/:id/method/:methodId/forever-plans/:planId',
							name: 'KTCForeverPlanEdit',
							component: KTCForeverPlanEdit,
							meta: {
								pageTitle: pageTitle.FOREVER_PLAN.EDIT_FOREVER,
								requireAuth: true,
								routePermissionKey: ROUTE_PERMISSION_KEY.PAYMENT,
							},
						},
						// Other bank
						{
							path: 'setting/:id/method/:methodId/bank-channels/create',
							name: ROUTE_NAME.PAYMENT_CREDIT_CARD_BANK_CHANNEL_CREATE,
							component: PaymentCreditCardBankChannelCreate,
							meta: {
								pageTitle: pageTitle.BANK_CHANNEL.CREATE,
								requireAuth: true,
								routePermissionKey: ROUTE_PERMISSION_KEY.PAYMENT,
							},
						},
						{
							path: 'setting/:id/method/:methodId/bank-channels/:channelId',
							name: ROUTE_NAME.PAYMENT_CREDIT_CARD_BANK_CHANNEL_EDIT,
							component: PaymentCreditCardBankChannelEdit,
							meta: {
								requireAuth: true,
								breadcrumbs: [
									{ title: 'Payment Settings', route: { name: ROUTE_NAME.PAYMENT_LIST } },
								],
								routePermissionKey: ROUTE_PERMISSION_KEY.PAYMENT,
							},
						},
						{
							path: 'setting/sub-groups/:id/method/:methodId',
							name: ROUTE_NAME.PAYMENT_SUBGROUPS_CHANNEL_EDIT,
							component: PaymentSubgroupsChannelEdit,
							meta: {
								requireAuth: true,
								breadcrumbs: [
									{ title: 'Payment Settings', route: { name: ROUTE_NAME.PAYMENT_LIST } },
								],
								routePermissionKey: ROUTE_PERMISSION_KEY.PAYMENT,
							},
						},
					],
				},
				// Shipping
				{
					path: '/shipping-setting',
					redirect: '/shipping-setting',
					name: ROUTE_NAME.SHIPPING,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						{
							path: '/',
							name: ROUTE_NAME.SHIPPING_SETTING,
							component: ShippingSetting,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.SHIPPING.SETTING,
								routePermissionKey: ROUTE_PERMISSION_KEY.SHIPPING,
							},
						},
						{
							path: ':shippingMethodId/rate/create',
							name: ROUTE_NAME.SHIPPING_RATE_CREATE,
							component: ShippingRateCreate,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.RATES.CREATE,
								routePermissionKey: ROUTE_PERMISSION_KEY.SHIPPING,
							},
						},
						{
							path: ':shippingMethodId/rate/:rateId',
							name: ROUTE_NAME.SHIPPING_RATE_EDIT,
							component: ShippingRateEdit,
							meta: {
								requireAuth: true,
								breadcrumbs: [
									{
										title: 'Shipping setting',
										route: { name: ROUTE_NAME.SHIPPING_SETTING },
									},
								],
								pageTitle: pageTitle.RATES.EDIT,
								routePermissionKey: ROUTE_PERMISSION_KEY.SHIPPING,
							},
						},
						{
							path: ':shippingMethodId/additional-fee/product/create',
							name: ROUTE_NAME.SHIPPING_PRODUCT_ADDITIONAL_FEE_CREATE,
							component: ShippingProductAdditionalFeeCreate,
							meta: {
								pageTitle: pageTitle.SKU_ADDITIONAL_FEE.CREATE,
								breadcrumbs: false,
								requireAuth: true,
								routePermissionKey: ROUTE_PERMISSION_KEY.SHIPPING,
							},
						},
						{
							path: ':shippingMethodId/additional-fee/product/edit/:feeId',
							name: ROUTE_NAME.SHIPPING_PRODUCT_ADDITIONAL_FEE_EDIT,
							component: ShippingProductAdditionalFeeEdit,
							meta: {
								pageTitle: pageTitle.SKU_ADDITIONAL_FEE.EDIT,
								requireAuth: true,
								breadcrumbs: [
									{
										title: 'Shipping setting',
										route: { name: ROUTE_NAME.SHIPPING_SETTING },
									},
								],
								routePermissionKey: ROUTE_PERMISSION_KEY.SHIPPING,
							},
						},
						{
							path: ':shippingMethodId/additional-fee/category/create',
							name: ROUTE_NAME.SHIPPING_CATEGORY_ADDITIONAL_FEE_CREATE,
							component: ShippingCategoryAdditionalFeeCreate,
							meta: {
								pageTitle: pageTitle.CATEGORY_ADDITIONAL_FEE.CREATE,
								requireAuth: true,
								routePermissionKey: ROUTE_PERMISSION_KEY.SHIPPING,
							},
						},
						{
							path: ':shippingMethodId/additional-fee/category/edit/:categoryAdditionalFeeId',
							name: ROUTE_NAME.SHIPPING_CATEGORY_ADDITIONAL_FEE_EDIT,
							component: ShippingCategoryAdditionalFeeEdit,
							meta: {
								pageTitle: pageTitle.CATEGORY_ADDITIONAL_FEE.EDIT,
								requireAuth: true,
								breadcrumbs: [
									{
										title: 'Shipping setting',
										route: { name: ROUTE_NAME.SHIPPING_SETTING },
									},
								],
								routePermissionKey: ROUTE_PERMISSION_KEY.SHIPPING,
							},
						},
						{
							path: ':shippingMethodId/additional-fee/postcode/create',
							name: ROUTE_NAME.SHIPPING_POSTCODE_ADDITIONAL_FEE_CREATE,
							component: ShippingPostcodeAdditionalFeeCreate,
							meta: {
								pageTitle: pageTitle.POSTCODE_ADDITIONAL_FEE.CREATE,
								requireAuth: true,
								routePermissionKey: ROUTE_PERMISSION_KEY.SHIPPING,
							},
						},
						{
							path: ':shippingMethodId/additional-fee/postcode/edit/:feeId',
							name: ROUTE_NAME.SHIPPING_POSTCODE_ADDITIONAL_FEE_EDIT,
							component: ShippingPostcodeAdditionalFeeEdit,
							meta: {
								pageTitle: pageTitle.POSTCODE_ADDITIONAL_FEE.EDIT,
								requireAuth: true,
								breadcrumbs: [
									{
										title: 'Shipping setting',
										route: { name: ROUTE_NAME.SHIPPING_SETTING },
									},
								],
								routePermissionKey: ROUTE_PERMISSION_KEY.SHIPPING,
							},
						},
					],
				},
				// Warehouse
				{
					path: 'warehouses',
					redirect: '/warehouses',
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						{
							path: '/',
							component: WarehouseList,
							name: ROUTE_NAME.WAREHOUSE_LIST,
							meta: {
								requireAuth: true,
								pageTitle: pageTitle.WAREHOUSE.LIST,
								showBreadcrumb: false,
								routePermissionKey: ROUTE_PERMISSION_KEY.WAREHOUSE,
							},
						},
						{
							path: 'create',
							name: ROUTE_NAME.WAREHOUSE_CREATE,
							component: WarehouseCreate,
							meta: {
								requireAuth: true,
								pageTitle: pageTitle.WAREHOUSE.CREATE,
								routePermissionKey: ROUTE_PERMISSION_KEY.WAREHOUSE,
							},
						},
						{
							path: ':id',
							name: ROUTE_NAME.WAREHOUSE_EDIT,
							component: WarehouseEdit,
							meta: {
								requireAuth: true,
								pageTitle: pageTitle.WAREHOUSE.EDIT,
								breadcrumbs: [
									{
										title: 'Warehouse/branch',
										route: { name: ROUTE_NAME.WAREHOUSE_LIST },
									},
								],
								routePermissionKey: ROUTE_PERMISSION_KEY.WAREHOUSE,
							},
						},
					],
				},
				// Sale Orders
				{
					path: 'sale',
					redirect: '/sale',
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						// Order Management
						{
							path: 'orders',
							component: {
								template: `<router-view :key="$route.fullPath" />`,
							},
							children: [
								// Order List
								{
									path: '/',
									name: ROUTE_NAME.ORDER_LIST,
									component: Orders,
									meta: {
										requireAuth: true,
										breadcrumbs: false,
										pageTitle: pageTitle.ORDER.LIST,
										routePermissionKey: ROUTE_PERMISSION_KEY.ORDER,
									},
								},
								// Order Detail
								{
									path: ':id',
									name: ROUTE_NAME.ORDER_DETAIL,
									component: OrderDetail,
									meta: {
										requireAuth: true,
										breadcrumbs: [
											{ title: 'Orders', route: { name: ROUTE_NAME.ORDER_LIST } },
										],
										routePermissionKey: ROUTE_PERMISSION_KEY.ORDER,
									},
								},
							],
						},

						// Shipment Management
						{
							path: 'shipments',
							component: {
								template: `<router-view :key="$route.fullPath" />`,
							},
							children: [
								// Shipment List
								{
									path: '/',
									name: ROUTE_NAME.SHIPMENT_LIST,
									component: Shipments,
									meta: {
										requireAuth: true,
										breadcrumbs: false,
										pageTitle: pageTitle.SHIPMENT.LIST,
										routePermissionKey: [ROUTE_PERMISSION_KEY.SHIPMENT, ROUTE_PERMISSION_KEY.BRANCH_SHIPMENTS],
									},
								},
								// Shipment Detail
								{
									path: ':id',
									name: ROUTE_NAME.SHIPMENT_DETAIL,
									component: ShipmentDetail,
									meta: {
										requireAuth: true,
										breadcrumbs: [
											{ title: 'Shipments', route: { name: ROUTE_NAME.SHIPMENT_LIST } },
										],
										routePermissionKey: [ROUTE_PERMISSION_KEY.SHIPMENT, ROUTE_PERMISSION_KEY.BRANCH_SHIPMENTS],
									},
								},
							],
						},

						// Order Auto-complete
						{
							path: 'order-auto-complete',
							component: {
								template: `<router-view :key="$route.fullPath" />`,
							},
							children: [
								// List
								{
									path: '/',
									name: ROUTE_NAME.ORDER_AUTO_COMPLETE,
									component: OrderAutoComplete,
									meta: {
										requireAuth: true,
										breadcrumbs: false,
										pageTitle: pageTitle.ORDER_AUTO_COMPLETE.LIST,
										routePermissionKey: ROUTE_PERMISSION_KEY.ORDER_AUTO_COMPLETE,
									},
								},
							],
						},
					],
				},
				// Customers
				{
					path: '/customers',
					redirect: '/',
					name: ROUTE_NAME.CUSTOMER_MANAGEMENT,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						{
							path: '',
							name: ROUTE_NAME.CUSTOMER_LIST,
							component: CustomerList,
							meta: {
								requireAuth: true,
								showBreadcrumb: false,
								pageTitle: pageTitle.CUSTOMER_MANAGEMENT.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.CUSTOMER,
							},
						},
						{
							path: 'create',
							name: ROUTE_NAME.CUSTOMER_CREATE,
							component: CustomerCreate,
							meta: {
								requireAuth: true,
								showBreadcrumb: false,
								pageTitle: pageTitle.CUSTOMER_MANAGEMENT.CREATE,
								routePermissionKey: ROUTE_PERMISSION_KEY.CUSTOMER,
							},
						},
						{
							path: ':id',
							name: ROUTE_NAME.CUSTOMER_EDIT,
							component: CustomerEdit,
							meta: {
								requireAuth: true,
								breadcrumbs: [
									{ title: 'Customer management', route: { name: ROUTE_NAME.CUSTOMER_LIST } },
								],
								routePermissionKey: ROUTE_PERMISSION_KEY.CUSTOMER,
							},
						},
					],
				},
				// Customer Group
				{
					path: '/customer-groups',
					redirect: '/',
					name: ROUTE_NAME.CUSTOMER_GROUP,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						{
							path: '',
							name: ROUTE_NAME.CUSTOMER_GROUP_LIST,
							component: CustomerGroupList,
							meta: {
								requireAuth: true,
								showBreadcrumb: false,
								pageTitle: pageTitle.CUSTOMER_GROUP.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.CUSTOMER_GROUP,
							},
						},
						{
							path: 'create',
							name: ROUTE_NAME.CUSTOMER_GROUP_CREATE,
							component: CustomerGroupCreate,
							meta: {
								requireAuth: true,
								showBreadcrumb: false,
								pageTitle: pageTitle.CUSTOMER_GROUP.CREATE,
								routePermissionKey: ROUTE_PERMISSION_KEY.CUSTOMER_GROUP,
							},
						},
						{
							path: ':id',
							name: ROUTE_NAME.CUSTOMER_GROUP_EDIT,
							component: CustomerGroupEdit,
							meta: {
								requireAuth: true,
								pageTitle: pageTitle.CUSTOMER_GROUP.EDIT,
								breadcrumbs: [
									{ title: 'Customer group', route: { name: ROUTE_NAME.CUSTOMER_GROUP_LIST } },
								],
								routePermissionKey: ROUTE_PERMISSION_KEY.CUSTOMER_GROUP,
							},
						},
					],
				},
				// Search Synonyms
				{
					path: '/search/search-synonyms',
					redirect: '/search/search-synonyms',
					name: ROUTE_NAME.SEARCH_SYNONYMS,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						{
							path: '',
							name: ROUTE_NAME.SEARCH_SYNONYMS_LIST,
							component: SearchSynonyms,
							meta: {
								requireAuth: true,
								showBreadcrumb: false,
								pageTitle: pageTitle.SEARCH_SYNONYMS.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.SEARCH_SYNONYMS,
							},
						},
					],
				},
				// Back in stock notification
				{
					path: '/back-in-stock-notification',
					redirect: '/back-in-stock-notification',
					name: ROUTE_NAME.BACK_IN_STOCK_NOTIFICATIONS,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						{
							path: '',
							name: ROUTE_NAME.BACK_IN_STOCK_NOTIFICATIONS_LIST,
							component: BackInStockNotification,
							meta: {
								requireAuth: true,
								showBreadcrumb: false,
								pageTitle: pageTitle.BACK_IN_STOCK_NOTIFICATIONS.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.BACK_IN_STOCK_NOTIFICATIONS,
							},
						},
					],
				},
				// Search Weights
				{
					path: '/search/search-weights',
					redirect: '/search/search-weights',
					name: ROUTE_NAME.SEARCH_WEIGHTS,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						{
							path: '',
							name: ROUTE_NAME.SEARCH_WEIGHT_LIST,
							component: SearchWeights,
							meta: {
								requireAuth: true,
								showBreadcrumb: false,
								pageTitle: pageTitle.SEARCH_WEIGHT.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.SEARCH_WEIGHT,
							},
						},
						{
							path: 'create',
							name: ROUTE_NAME.SEARCH_WEIGHT_CREATE,
							component: SearchWeightCreate,
							meta: {
								requireAuth: true,
								showBreadcrumb: false,
								pageTitle: pageTitle.SEARCH_WEIGHT.CREATE,
								routePermissionKey: ROUTE_PERMISSION_KEY.SEARCH_WEIGHT,
							},
						},
						{
							path: ':id',
							name: ROUTE_NAME.SEARCH_WEIGHT_EDIT,
							component: SearchWeightEdit,
							meta: {
								requireAuth: true,
								pageTitle: pageTitle.SEARCH_WEIGHT.EDIT,
								breadcrumbs: [
									{ title: 'Search weight', route: { name: ROUTE_NAME.SEARCH_WEIGHT_LIST } },
								],
								routePermissionKey: ROUTE_PERMISSION_KEY.SEARCH_WEIGHT,
							},
						},
					],
				},
				// Search result caching
				{
					path: '/cache/clear-search-result-cache',
					redirect: '/cache/clear-search-result-cache',
					name: ROUTE_NAME.SEARCH_RESULT_CACHE,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						{
							path: '',
							name: ROUTE_NAME.SEARCH_RESULT_CACHE_LIST,
							component: SearchResultCache,
							meta: {
								requireAuth: true,
								showBreadcrumb: false,
								pageTitle: pageTitle.SEARCH_RESULT_CACHE.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.SEARCH_RESULT_CACHE,
							},
						},
					],
				},
				// Pages
				{
					path: '/pages',
					redirect: '/pages',
					component: {
						render(c) {
							return c('router-view');
						},
					},
					children: [
						{
							path: '/',
							name: ROUTE_NAME.PAGES,
							component: Pages,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.PAGE.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.STATIC_PAGE,
							},
						},
						{
							path: 'create',
							name: ROUTE_NAME.PAGE_CREATE,
							component: PageCreate,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.PAGE.CREATE,
								routePermissionKey: ROUTE_PERMISSION_KEY.STATIC_PAGE,
							},
						},
						{
							path: ':id',
							name: ROUTE_NAME.PAGE_EDIT,
							component: PageEdit,
							meta: {
								requireAuth: true,
								breadcrumbs: [
									{ title: 'Static pages', route: { name: ROUTE_NAME.PAGES } },
								],
								pageTitle: pageTitle.PAGE.EDIT,
								routePermissionKey: ROUTE_PERMISSION_KEY.STATIC_PAGE,
							},
						},
					],
					meta: {
						requireAuth: true,
					},
				},
				// Homepage
				{
					path: '/homepage',
					redirect: '/homepage',
					name: ROUTE_NAME.HOMEPAGE,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						{
							path: '',
							name: ROUTE_NAME.WIDGET_LIST,
							component: Homepage,
							props: { activeTab: 'WidgetList' },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.HOMEPAGE.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.HOMEPAGE,
							},
						},
					],
				},
				// Sub headers
				{
					path: '/sub-headers',
					redirect: '/sub-headers',
					name: ROUTE_NAME.SUB_HEADERS,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						{
							path: '',
							name: ROUTE_NAME.SUB_HEADER_HOMEPAGE,
							component: SubHeaders,
							props: { subHeaderType: SUB_HEADER_TYPE.homepage },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.SUB_HEADERS.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.SUB_HEADERS,
							},
						},
						{
							path: 'cart',
							name: ROUTE_NAME.SUB_HEADER_CART,
							component: SubHeaders,
							props: { subHeaderType: SUB_HEADER_TYPE.cart },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.SUB_HEADERS.CART,
								routePermissionKey: ROUTE_PERMISSION_KEY.SUB_HEADERS,
							},
						},
						{
							path: 'static-page',
							name: ROUTE_NAME.SUB_HEADER_STATIC_PAGE,
							component: SubHeaders,
							props: { subHeaderType: SUB_HEADER_TYPE.staticPage },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.SUB_HEADERS.STATIC_PAGE,
								routePermissionKey: ROUTE_PERMISSION_KEY.SUB_HEADERS,
							},
						},
					],
				},
				// Banner group
				{
					path: '/banner',
					redirect: '/homepage',
					name: ROUTE_NAME.BANNER,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						{
							path: 'hero',
							name: ROUTE_NAME.BANNER_HERO_LIST,
							component: Homepage,
							props: { bannerType: BANNER_TYPE.hero },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.BANNER_HERO.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.HOMEPAGE,
							},
						},
						{
							path: 'hero/create',
							name: ROUTE_NAME.BANNER_HERO_CREATE,
							component: BannerHeroCreate,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.BANNER_HERO.CREATE,
								routePermissionKey: ROUTE_PERMISSION_KEY.HOMEPAGE,
							},
						},
						{
							path: 'hero/:id',
							name: ROUTE_NAME.BANNER_HERO_EDIT,
							component: BannerHeroEdit,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								routePermissionKey: ROUTE_PERMISSION_KEY.HOMEPAGE,
							},
						},
						{
							path: 'highlight',
							name: ROUTE_NAME.BANNER_HIGHLIGHT_LIST,
							component: Homepage,
							props: { bannerType: BANNER_TYPE.highlight },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.BANNER_HIGHLIGHT.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.HOMEPAGE,
							},
						},
						{
							path: 'highlight/create',
							name: ROUTE_NAME.BANNER_HIGHLIGHT_CREATE,
							component: BannerHighlightCreate,
							props: { bannerType: BANNER_TYPE.highlight },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.BANNER_HIGHLIGHT.CREATE,
								routePermissionKey: ROUTE_PERMISSION_KEY.HOMEPAGE,
							},
						},
						{
							path: 'highlight/:id',
							name: ROUTE_NAME.BANNER_HIGHLIGHT_EDIT,
							component: BannerHighlightEdit,
							props: { bannerType: BANNER_TYPE.highlight },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								routePermissionKey: ROUTE_PERMISSION_KEY.HOMEPAGE,
							},
						},
						{
							path: 'small',
							name: ROUTE_NAME.BANNER_SMALL_LIST,
							component: Homepage,
							props: { bannerType: BANNER_TYPE.small },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.BANNER_SMALL.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.HOMEPAGE,
							},
						},
						{
							path: 'small/create',
							name: ROUTE_NAME.BANNER_SMALL_CREATE,
							component: BannerSmallCreate,
							props: { bannerType: BANNER_TYPE.small },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.BANNER_SMALL.CREATE,
								routePermissionKey: ROUTE_PERMISSION_KEY.HOMEPAGE,
							},
						},
						{
							path: 'small/:id',
							name: ROUTE_NAME.BANNER_SMALL_EDIT,
							component: BannerSmallEdit,
							props: { bannerType: BANNER_TYPE.small },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								routePermissionKey: ROUTE_PERMISSION_KEY.HOMEPAGE,
							},
						},
						{
							path: 'text',
							name: ROUTE_NAME.BANNER_TEXT_LIST,
							component: Homepage,
							props: { bannerType: BANNER_TYPE.text },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.BANNER_TEXT.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.HOMEPAGE,
							},
						},
						{
							path: 'text/create',
							name: ROUTE_NAME.BANNER_TEXT_CREATE,
							component: BannerTextCreate,
							props: { bannerType: BANNER_TYPE.text },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.BANNER_TEXT.CREATE,
								routePermissionKey: ROUTE_PERMISSION_KEY.HOMEPAGE,
							},
						},
						{
							path: 'text/:id',
							name: ROUTE_NAME.BANNER_TEXT_EDIT,
							component: BannerTextEdit,
							props: { bannerType: BANNER_TYPE.text },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								routePermissionKey: ROUTE_PERMISSION_KEY.HOMEPAGE,
							},
						},
						{
							path: 'picture',
							name: ROUTE_NAME.BANNER_PICTURE_THUMBNAIL_LIST,
							component: Homepage,
							props: { bannerType: BANNER_TYPE.picture },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.BANNER_PICTURE_THUMBNAIL.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.HOMEPAGE,
							},
						},
						{
							path: 'picture/create',
							name: ROUTE_NAME.BANNER_PICTURE_THUMBNAIL_CREATE,
							component: BannerPictureThumbnailCreate,
							props: { bannerType: BANNER_TYPE.picture },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.BANNER_PICTURE_THUMBNAIL.CREATE,
								routePermissionKey: ROUTE_PERMISSION_KEY.HOMEPAGE,
							},
						},
						{
							path: 'picture/:id',
							name: ROUTE_NAME.BANNER_PICTURE_THUMBNAIL_EDIT,
							component: BannerPictureThumbnailEdit,
							props: { bannerType: BANNER_TYPE.picture },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								routePermissionKey: ROUTE_PERMISSION_KEY.HOMEPAGE,
							},
						},
					],
				},
				// Marketing category
				{
					path: '/marketing-campaigns',
					redirect: '/marketing-campaigns',
					name: ROUTE_NAME.MARKETING,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						// Marketing Category List
						{
							path: '/',
							name: ROUTE_NAME.MARKETING_CAMPAIGN_LIST,
							component: MarketingCampaignList,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.MARKETING_CAMPAIGN.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.CAMPAIGN_MANAGEMENT,
							},
						},
						{
							path: 'create',
							name: ROUTE_NAME.MARKETING_CAMPAIGN_CREATE,
							component: MarketingCampaignCreate,
							meta: {
								requireAuth: true,
								pageTitle: pageTitle.MARKETING_CAMPAIGN.CREATE,
								breadcrumbs: false,
								routePermissionKey: ROUTE_PERMISSION_KEY.CAMPAIGN_MANAGEMENT,
							},
						},
						{
							path: ':id',
							name: ROUTE_NAME.MARKETING_CAMPAIGN_EDIT,
							component: MarketingCampaignEdit,
							meta: {
								requireAuth: true,
								pageTitle: pageTitle.MARKETING_CAMPAIGN.EDIT,
								breadcrumbs: [
									{
										title: pageTitle.MARKETING_CAMPAIGN.BREADCRUMBS_LIST,
										route: { name: ROUTE_NAME.MARKETING_CAMPAIGN_LIST },
									},
								],
								routePermissionKey: ROUTE_PERMISSION_KEY.CAMPAIGN_MANAGEMENT,
							},
						},
						{
							path: ':id/attribute-key',
							name: ROUTE_NAME.MARKETING_CAMPAIGN_ATTRIBUTE_EDIT,
							component: MarketingCampaignAttributeEdit,
							meta: {
								requireAuth: true,
								pageTitle: pageTitle.MARKETING_CAMPAIGN.ATTRIBUTE_EDIT,
								breadcrumbs: [
									{
										title: pageTitle.MARKETING_CAMPAIGN.BREADCRUMBS_LIST,
										route: { name: ROUTE_NAME.MARKETING_CAMPAIGN_LIST },
									},
								],
								routePermissionKey: ROUTE_PERMISSION_KEY.CAMPAIGN_MANAGEMENT,
							},
						},
					],
				},
				// Promotions
				{
					path: '/promotions',
					redirect: '/promotions',
					name: ROUTE_NAME.PROMOTION,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						// Promotion List
						{
							path: '/',
							name: ROUTE_NAME.PROMOTION_LIST,
							component: Promotions,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.PROMOTION.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.PROMOTION,
							},
						},
						// Promotion Create
						{
							path: 'create',
							name: ROUTE_NAME.PROMOTION_CREATE,
							component: PromotionCreate,
							meta: {
								pageTitle: pageTitle.PROMOTION.CREATE,
								requireAuth: true,
								routePermissionKey: ROUTE_PERMISSION_KEY.PROMOTION,
							},
						},
						// Promotion Edit
						{
							path: ':id',
							name: ROUTE_NAME.PROMOTION_EDIT,
							component: PromotionEdit,
							meta: {
								requireAuth: true,
								breadcrumbs: [
									{ title: 'Promotions', route: { name: ROUTE_NAME.PROMOTION_LIST } },
								],
								pageTitle: pageTitle.PROMOTION.EDIT,
								routePermissionKey: ROUTE_PERMISSION_KEY.PROMOTION,
							},
						},
					],
				},
				// Pre-orders
				{
					path: '/preorders',
					redirect: '/preorders',
					name: ROUTE_NAME.PRE_ORDERS,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						// Pre-order List
						{
							path: '/',
							name: ROUTE_NAME.PRE_ORDER_LIST,
							component: PreOrders,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.PREORDER.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.PRE_ORDER,
							},
						},
						// PreOrder Create
						{
							path: 'create',
							name: ROUTE_NAME.PRE_ORDER_CREATE,
							component: PreOrderCreate,
							meta: {
								requireAuth: true,
								showBreadcrumb: false,
								pageTitle: pageTitle.PREORDER.CREATE,
								routePermissionKey: ROUTE_PERMISSION_KEY.PRE_ORDER,
							},
						},
						// PreOrder Edit
						{
							path: ':id',
							name: ROUTE_NAME.PRE_ORDER_EDIT,
							component: PreOrderEdit,
							meta: {
								requireAuth: true,
								breadcrumbs: [
									{
										title: 'Pre Orders',
										route: { name: ROUTE_NAME.PRE_ORDER_LIST },
									},
								],
								pageTitle: pageTitle.PREORDER.EDIT,
								routePermissionKey: ROUTE_PERMISSION_KEY.PRE_ORDER,
							},
						},
						// PreOrder - [Create] Pickup at store, branch, sku & schedule
						{
							path: ':id/pickup-at-store',
							name: ROUTE_NAME.PRE_ORDER_PICKUP_AT_STORE_CREATE,
							component: PreOrderPickupAtStoreCreate,
							meta: {
								requireAuth: true,
								breadcrumbs: [
									{
										title: 'Pre Orders',
										route: { name: ROUTE_NAME.PRE_ORDER_LIST },
									},
									{
										title: 'Edit Pre Order',
										route: { name: ROUTE_NAME.PRE_ORDER_EDIT },
									},
								],
								pageTitle: pageTitle.PREORDER.PICKUP_AT_STORE.CREATE,
								routePermissionKey: ROUTE_PERMISSION_KEY.PRE_ORDER,
							},
						},
						// PreOrder - [Edit] Pickup at store, branch, sku & schedule
						{
							path: ':id/pickup-at-store/:pickupId',
							name: ROUTE_NAME.PRE_ORDER_PICKUP_AT_STORE_EDIT,
							component: PreOrderPickupAtStoreEdit,
							meta: {
								requireAuth: true,
								breadcrumbs: [
									{
										title: 'Pre Orders',
										route: { name: ROUTE_NAME.PRE_ORDER_LIST },
									},
									{
										title: 'Edit Pre Order',
										route: { name: ROUTE_NAME.PRE_ORDER_EDIT },
									},
								],
								pageTitle: pageTitle.PREORDER.PICKUP_AT_STORE.EDIT,
								routePermissionKey: ROUTE_PERMISSION_KEY.PRE_ORDER,
							},
						},
					],
				},
				// Product feed
				{
					path: 'feeds',
					redirect: '/feeds',
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						{
							path: '/',
							component: ProductFeedList,
							name: ROUTE_NAME.PRODUCT_FEED_LIST,
							meta: {
								requireAuth: true,
								pageTitle: pageTitle.FEED.LIST,
								showBreadcrumb: false,
								routePermissionKey: ROUTE_PERMISSION_KEY.FEED,
							},
						},
						{
							path: 'create',
							name: ROUTE_NAME.PRODUCT_FEED_CREATE,
							component: ProductFeedCreate,
							meta: {
								requireAuth: true,
								pageTitle: pageTitle.FEED.CREATE,
								showBreadcrumb: false,
								routePermissionKey: ROUTE_PERMISSION_KEY.FEED,
							},
						},
						{
							path: ':id',
							name: ROUTE_NAME.PRODUCT_FEED_EDIT,
							component: ProductFeedEdit,
							meta: {
								requireAuth: true,
								pageTitle: pageTitle.FEED.EDIT,
								breadcrumbs: [
									{
										title: 'Product Feed',
										route: { name: ROUTE_NAME.PRODUCT_FEED_LIST },
									},
								],
								routePermissionKey: ROUTE_PERMISSION_KEY.FEED,
							},
						},
					],
				},
				// Flash-sales
				{
					path: '/flashsales',
					redirect: '/flashsales',
					name: ROUTE_NAME.FLASH_SALES,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						// Flash sale List
						{
							path: '/',
							name: ROUTE_NAME.FLASH_SALE_LIST,
							component: FlashSales,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.FLASHSALE.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.FLASH_SALE,
							},
						},
						// Flash sale Create
						{
							path: 'create',
							name: ROUTE_NAME.FLASH_SALE_CREATE,
							component: FlashSaleCreate,
							meta: {
								requireAuth: true,
								showBreadcrumb: false,
								pageTitle: pageTitle.FLASHSALE.CREATE,
								routePermissionKey: ROUTE_PERMISSION_KEY.FLASH_SALE,
							},
						},
						// Flash sale Edit
						{
							path: ':id',
							name: ROUTE_NAME.FLASH_SALE_EDIT,
							component: FlashSaleEdit,
							meta: {
								requireAuth: true,
								breadcrumbs: [
									{ title: 'Flash sale list', route: { name: ROUTE_NAME.FLASH_SALE_LIST } },
								],
								pageTitle: pageTitle.FLASHSALE.EDIT,
								routePermissionKey: ROUTE_PERMISSION_KEY.FLASH_SALE,
							},
						},
					],
				},
				// Microsite
				{
					path: '/microsite',
					redirect: '/microsite',
					name: ROUTE_NAME.MICROSITE,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						{
							path: '/',
							name: ROUTE_NAME.MICROSITE_WIDGETS,
							component: MicrositeWidgets,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.MICROSITE.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.MICROSITE,
							},
						},
						{
							path: 'hero',
							name: ROUTE_NAME.MICROSITE_BANNER_HERO_LIST,
							component: MicrositeWidgets,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.BANNER_HERO.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.MICROSITE,
							},
							props: { isMicrosite: true },
						},
						{
							path: 'hero/create',
							name: ROUTE_NAME.MICROSITE_BANNER_HERO_CREATE,
							component: BannerHeroCreate,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.BANNER_HERO.CREATE,
								routePermissionKey: ROUTE_PERMISSION_KEY.MICROSITE,
							},
							props: { isMicrosite: true },
						},
						{
							path: 'hero/:id',
							name: ROUTE_NAME.MICROSITE_BANNER_HERO_EDIT,
							component: BannerHeroEdit,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								routePermissionKey: ROUTE_PERMISSION_KEY.MICROSITE,
							},
							props: { isMicrosite: true },
						},
						{
							path: 'highlight',
							name: ROUTE_NAME.MICROSITE_BANNER_HIGHLIGHT_LIST,
							component: MicrositeWidgets,
							props: { bannerType: BANNER_TYPE.highlight, isMicrosite: true },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.BANNER_HIGHLIGHT.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.MICROSITE,
							},
						},
						{
							path: 'highlight/create',
							name: ROUTE_NAME.MICROSITE_BANNER_HIGHLIGHT_CREATE,
							component: BannerHighlightCreate,
							props: { bannerType: BANNER_TYPE.highlight, isMicrosite: true },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.BANNER_HIGHLIGHT.CREATE,
								routePermissionKey: ROUTE_PERMISSION_KEY.MICROSITE,
							},
						},
						{
							path: 'highlight/:id',
							name: ROUTE_NAME.MICROSITE_BANNER_HIGHLIGHT_EDIT,
							component: BannerHighlightEdit,
							props: { bannerType: BANNER_TYPE.highlight, isMicrosite: true },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								routePermissionKey: ROUTE_PERMISSION_KEY.MICROSITE,
							},
						},
						{
							path: 'shortcut',
							name: ROUTE_NAME.MICROSITE_BANNER_SHORTCUT_LIST,
							component: MicrositeWidgets,
							props: { bannerType: BANNER_TYPE.shortcut, isMicrosite: true },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.BANNER_SHORTCUT.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.MICROSITE,
							},
						},
						{
							path: 'shortcut/create',
							name: ROUTE_NAME.MICROSITE_BANNER_SHORTCUT_CREATE,
							component: BannerShortcutCreate,
							props: { bannerType: BANNER_TYPE.shortcut, isMicrosite: true },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.BANNER_SHORTCUT.CREATE,
								routePermissionKey: ROUTE_PERMISSION_KEY.MICROSITE,
							},
						},
						{
							path: 'shortcut/:id',
							name: ROUTE_NAME.MICROSITE_BANNER_SHORTCUT_EDIT,
							component: BannerSmallEdit,
							props: { bannerType: BANNER_TYPE.shortcut, isMicrosite: true },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								routePermissionKey: ROUTE_PERMISSION_KEY.MICROSITE,
							},
						},


						{
							path: 'content',
							name: ROUTE_NAME.MICROSITE_BANNER_CONTENT_LIST,
							component: MicrositeWidgets,
							props: { bannerType: BANNER_TYPE.content, isMicrosite: true },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.BANNER_CONTENT.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.MICROSITE,
							},
						},
						{
							path: 'content/create',
							name: ROUTE_NAME.MICROSITE_BANNER_CONTENT_CREATE,
							component: BannerSmallCreate,
							props: { bannerType: BANNER_TYPE.content, isMicrosite: true },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.BANNER_CONTENT.CREATE,
								routePermissionKey: ROUTE_PERMISSION_KEY.MICROSITE,
							},
						},
						{
							path: 'shortcut/:id',
							name: ROUTE_NAME.MICROSITE_BANNER_CONTENT_EDIT,
							component: BannerSmallEdit,
							props: { bannerType: BANNER_TYPE.content, isMicrosite: true },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								routePermissionKey: ROUTE_PERMISSION_KEY.MICROSITE,
							},
						},
					],
				},
				// Audit logs
				{
					path: '/audit-logs',
					redirect: '/audit-logs',
					name: ROUTE_NAME.AUDIT_LOGS,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						{
							path: '/',
							name: ROUTE_NAME.AUDIT_LOGS_LIST,
							component: AuditLogs,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.AUDIT_LOG.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.AUDIT_LOG,
							},
						},
						// Audit log Detail
						{
							path: ':id',
							name: ROUTE_NAME.AUDIT_LOG_DETAIL,
							component: AuditLogDetail,
							meta: {
								requireAuth: true,
								breadcrumbs: [
									{ title: 'Audit log', route: { name: ROUTE_NAME.AUDIT_LOGS_LIST } },
								],
								pageTitle: pageTitle.AUDIT_LOG.DETAIL,
								routePermissionKey: ROUTE_PERMISSION_KEY.AUDIT_LOG,
							},
						},
					],
				},
				// Abandoned cart
				{
					path: '/abandoned-carts',
					redirect: '/abandoned-carts',
					name: ROUTE_NAME.ABANDONED_CART,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						{
							path: '/',
							name: ROUTE_NAME.ABANDONED_CART_LIST,
							component: AbandonedCarts,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.ABANDONED_CART.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.ABANDONED_CART,
							},
						},
					],
				},
				// Bundle set
				{
					path: '/bundle-settings',
					redirect: '/bundle-settings',
					name: ROUTE_NAME.BUNDLE_SET,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						// Bundle set list
						{
							path: '/',
							name: ROUTE_NAME.BUNDLE_SET_LIST,
							component: BundleSetList,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.BUNDLE_SET.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.BUNDLE_SET,
							},
						},
						// Bundle set create
						{
							path: 'create',
							name: ROUTE_NAME.BUNDLE_SET_CREATE,
							component: BundleSetCreate,
							meta: {
								pageTitle: pageTitle.BUNDLE_SET.CREATE,
								requireAuth: true,
								routePermissionKey: ROUTE_PERMISSION_KEY.BUNDLE_SET,

							},
						},
						// Bundle set edit
						{
							path: ':id',
							name: ROUTE_NAME.BUNDLE_SET_EDIT,
							component: BundleSetEdit,
							meta: {
								requireAuth: true,
								breadcrumbs: [
									{ title: 'Bundles', route: { name: ROUTE_NAME.BUNDLE_SET_LIST } },
								],
								pageTitle: pageTitle.BUNDLE_SET.EDIT,
								routePermissionKey: ROUTE_PERMISSION_KEY.BUNDLE_SET,
							},
						},
					],
				},
				// On-site messaging
				{
					path: '/on-site-messaging',
					redirect: '/on-site-messaging/popup',
					name: ROUTE_NAME.ON_SITE_MESSAGING,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						{
							path: 'popup',
							name: ROUTE_NAME.ON_SITE_MESSAGE_POPUP_LIST,
							component: OnSiteMessageList,
							props: { messageType: ON_SITE_MESSAGE_TYPES.POPUP },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.ON_SITE_MESSAGE_POPUP.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.ON_SITE_MESSAGING,
							},
						},
						{
							path: 'popup/create',
							name: ROUTE_NAME.ON_SITE_MESSAGE_POPUP_CREATE,
							component: OnSiteMessagePopupCreate,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.ON_SITE_MESSAGE_POPUP.CREATE,
								routePermissionKey: ROUTE_PERMISSION_KEY.ON_SITE_MESSAGING,
							},
						},
						{
							path: 'popup/:id',
							name: ROUTE_NAME.ON_SITE_MESSAGE_POPUP_EDIT,
							component: OnSiteMessagePopupEdit,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								routePermissionKey: ROUTE_PERMISSION_KEY.ON_SITE_MESSAGING,
							},
						},

						{
							path: 'strip',
							name: ROUTE_NAME.ON_SITE_MESSAGE_STRIP_LIST,
							component: OnSiteMessageList,
							props: { messageType: ON_SITE_MESSAGE_TYPES.STRIP },
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.ON_SITE_MESSAGE_STRIP.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.ON_SITE_MESSAGING,
							},
						},
						{
							path: 'strip/create',
							name: ROUTE_NAME.ON_SITE_MESSAGE_STRIP_CREATE,
							component: OnSiteMessageStripCreate,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.ON_SITE_MESSAGE_STRIP.CREATE,
								routePermissionKey: ROUTE_PERMISSION_KEY.ON_SITE_MESSAGING,
							},
						},
						{
							path: 'strip/:id',
							name: ROUTE_NAME.ON_SITE_MESSAGE_STRIP_EDIT,
							component: OnSiteMessageStripEdit,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								routePermissionKey: ROUTE_PERMISSION_KEY.ON_SITE_MESSAGING,
							},
						},
					],
				},
				// Limit product
				{
					path: '/limit-product',
					redirect: '/limit-product',
					name: ROUTE_NAME.LIMIT_PRODUCT_PER_ACCOUNT_LIST,
					component: {
						template: `<router-view :key="$route.fullPath" />`,
					},
					children: [
						// list
						{
							path: '/',
							name: ROUTE_NAME.LIMIT_PRODUCT_PER_ACCOUNT,
							component: LimitProductList,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.LIMIT_PRODUCT_PER_ACCOUNT.LIST,
								routePermissionKey: ROUTE_PERMISSION_KEY.LIMIT_PRODUCT_PER_ACCOUNT,
							},
						},

						// create
						{
							path: 'create',
							name: ROUTE_NAME.LIMIT_PRODUCT_PER_ACCOUNT_CREATE,
							component: LimitProductCreate,
							meta: {
								requireAuth: true,
								breadcrumbs: false,
								pageTitle: pageTitle.LIMIT_PRODUCT_PER_ACCOUNT.CREATE,
								routePermissionKey: ROUTE_PERMISSION_KEY.LIMIT_PRODUCT_PER_ACCOUNT,
							},
						},

						// edit
						{
							path: ':id',
							name: ROUTE_NAME.LIMIT_PRODUCT_PER_ACCOUNT_EDIT,
							component: LimitProductEdit,
							meta: {
								requireAuth: true,
								breadcrumbs: [
									{ title: 'Limit Product', route: { name: ROUTE_NAME.LIMIT_PRODUCT_PER_ACCOUNT_LIST } },
								],
								pageTitle: pageTitle.LIMIT_PRODUCT_PER_ACCOUNT.EDIT,
								routePermissionKey: ROUTE_PERMISSION_KEY.LIMIT_PRODUCT_PER_ACCOUNT,
							},
						},
					],
				},
			],
		},
		{
			path: '/forbidden',
			component: TheContainer,
			children: [
				{
					path: '/',
					name: ROUTE_NAME.FORBIDDEN,
					component: PageForbidden,
					meta: {
						pageTitle: 'No permission',
					},
				},
			],
		},
		{
			path: '/internal-server-error',
			component: TheContainer,
			children: [
				{
					path: '/',
					name: ROUTE_NAME.PAGE_SERVER_ERROR,
					component: PageServerError,
					meta: {
						pageTitle: 'Internal server error',
					},
				},
			],
		},
		{
			path: '*',
			component: TheContainer,
			children: [
				{
					path: '/',
					name: ROUTE_NAME.PAGE_NOT_FOUND,
					component: PageNotFound,
					meta: {
						pageTitle: 'Page not found',
					},
				},
			],
		},
	],
});

export const authManagement = (vuexStore) => async (to, from, next) => {
	try {
		const auth = vuexStore.state.auth;

		if (auth.isLoggedIn) {
			// Whitelist route name
			const whitelistRoutes = [
				ROUTE_NAME.FORBIDDEN,
				ROUTE_NAME.PAGE_SERVER_ERROR,
				ROUTE_NAME.PAGE_NOT_FOUND,
				ROUTE_NAME.USER_PROFILE,
				ROUTE_NAME.LOGOUT,
				ROUTE_NAME.LOGIN,
			];

			/**
			 * Check user permission in vuex if user not go to logout page
			 * if not found, dispatch to get permission
			 * */

			if (!vuexStore.state.profile.permissions.length && to.name !== 'Logout') {
				await vuexStore.dispatch('profile/getProfile');

				// If got 500 when get profile, show internal server error page
				if (vuexStore.state.profile.isError500) {
					return next({ name: ROUTE_NAME.PAGE_SERVER_ERROR });
				}
			}

			// After logged in re-direct to users page
			if (to.name === ROUTE_NAME.LOGIN) {
				const routeName = vuexStore.getters['profile/getDefaultRouteName'];
				return next({ name: routeName });

			// Don't check permission for whitelist route
			} else if (whitelistRoutes.includes(to.name)) {
				return next();
			}

			const routeDestinationKey = to.meta.routePermissionKey || null;
			const routePermission = vuexStore.state.profile.permissions
				.filter((permission) => {
					if (Array.isArray(routeDestinationKey)) {
						return routeDestinationKey.includes(permission.key);
					}
					return permission.key === routeDestinationKey;
				});

			const redirectRoutePermission = routePermission.find((route) => route.permissions.view);

			// Redirect to destination page if user has permission or destination is root
			if (redirectRoutePermission || to.name === ROUTE_NAME.INDEX) {
				return next();
			}

			// If user doesn't have permission, redirect to 403 page
			return next({ name: ROUTE_NAME.FORBIDDEN });

		// Redirect to login page if isLoggedIn is false and destination route is required auth
		} else if (!auth.isLoggedIn && to.meta.requireAuth) {
			// When access require auth page but is not log in yet,
			// re-direct to login page
			return next({ name: ROUTE_NAME.LOGIN });
		}

		return next();
	} catch (e) {
		return next({ name: ROUTE_NAME.LOGIN });
	}
};

router.beforeEach(authManagement(store));

// Display meta title by using pageTitle from router configuration.
router.beforeEach((to, from, next) => {
	document.title = to.meta && to.meta.pageTitle ? `${to.meta.pageTitle} | ${pageTitle.DEFAULT_TITLE}` : pageTitle.DEFAULT_TITLE;
	next();
});

export default router;
