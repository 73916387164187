export const ROUTE_NAME = {
	LOGIN: 'Login',
	LOGOUT: 'Logout',
	HOME: 'Home',
	USERS: 'Users',
	USER_LISTS: 'UserLists',
	USER_PROFILE: 'UserProfile',
	USER_CREATE: 'UserCreate',
	USER_ROLE: 'UserRole',
	USER_ROLE_CREATE: 'UserRoleCreate',
	USER_ROLE_EDIT: 'UserRoleEdit',
	USER_EDIT: 'UserEdit',
	CATEGORIES: 'Categories',
	CATEGORY_LISTS: 'CategoryLists',
	CREATE_NEW_CATEGORY: 'CreateNewCategory',
	CATEGORY_EDIT: 'CategoryEdit',
	CATEGORY_ATTRIBUTE_EDIT: 'CategoryAttributeEdit',
	PRODUCTS: 'Products',
	PRODUCTS_LISTS: 'ProductsLists',
	PRODUCT_EDIT: 'ProductEdit',
	PRODUCT_IMPORT_HISTORY: 'ProductImportHistory',
	ATTRIBUTE_SET: 'AttributeSet',
	ATTRIBUTE_SET_LIST: 'AttributeSetList',
	ATTRIBUTE_SET_EDIT: 'AttributeSetEdit',
	ATTRIBUTE_KEY: 'AttributeKey',
	ATTRIBUTE_KEY_LIST: 'AttributeKeyList',
	PRODUCT_LABELS: 'ProductLabels',
	PRODUCT_LABEL_LIST: 'ProductLabelList',
	PRODUCT_LABEL_CREATE: 'ProductLabelCreate',
	PRODUCT_LABEL_BULK_ATTACH: 'ProductLabelBulkAttach',
	PRODUCT_LABEL_EDIT: 'ProductLabelEdit',
	PAYMENTS: 'Payments',
	PAYMENT_LIST: 'PaymentList',
	PAYMENT_SETTING: 'PaymentSetting',
	PAYMENT_METHOD_SETTING: 'PaymentMethodSetting',
	INSTALLMENT_PLAN_CREATE: 'InstallmentPlanCreate',
	INSTALLMENT_PLAN_CHANNEL: 'InstallmentPlanChannel',
	INSTALLMENT_PLAN_CHANNEL_CREATE: 'InstallmentPlanChannelCreate',
	INSTALLMENT_PLAN_EDIT: 'InstallmentPlanEdit',
	INSTALLMENT_PLAN_CHANNEL_EDIT: 'InstallmentPlanChannelEdit',
	KTC_INSTALLMENT_PLAN_LIST: 'KTCInstallmentPlanList',
	PAYMENT_CREDIT_CARD_BANK_CHANNEL_CREATE: 'PaymentCreditCardBankChannelCreate',
	PAYMENT_CREDIT_CARD_BANK_CHANNEL_EDIT: 'PaymentCreditCardBankChannelEdit',
	PAYMENT_SUBGROUPS_CHANNEL_EDIT: 'PaymentSubgroupsChannelEdit',
	SHIPPING: 'Shipping',
	SHIPPING_SETTING: 'ShippingSetting',
	SHIPPING_RATE_CREATE: 'ShippingRateCreate',
	SHIPPING_RATE_EDIT: 'ShippingRateEdit',
	SHIPPING_PRODUCT_ADDITIONAL_FEE_CREATE: 'ShippingProductAdditionalFeeCreate',
	SHIPPING_PRODUCT_ADDITIONAL_FEE_EDIT: 'ShippingProductAdditionalFeeEdit',
	SHIPPING_CATEGORY_ADDITIONAL_FEE_CREATE:
		'ShippingCategoryAdditionalFeeCreate',
	SHIPPING_CATEGORY_ADDITIONAL_FEE_EDIT: 'ShippingCategoryAdditionalFeeEdit',
	SHIPPING_POSTCODE_ADDITIONAL_FEE_CREATE:
		'ShippingPostcodeAdditionalFeeCreate',
	SHIPPING_POSTCODE_ADDITIONAL_FEE_EDIT: 'ShippingPostcodeAdditionalFeeEdit',
	WAREHOUSE_LIST: 'WarehouseList',
	WAREHOUSE_CREATE: 'WarehouseCreate',
	WAREHOUSE_EDIT: 'WarehouseEdit',
	ORDER_LIST: 'OrderList',
	ORDER_DETAIL: 'OrderDetail',
	SHIPMENT_LIST: 'ShipmentList',
	SHIPMENT_DETAIL: 'ShipmentDetail',
	CUSTOMER_MANAGEMENT: 'CustomerManagement',
	CUSTOMER_LIST: 'CustomerList',
	CUSTOMER_CREATE: 'CustomerCreate',
	CUSTOMER_EDIT: 'CustomerEdit',
	CUSTOMER_GROUP: 'CustomerGroup',
	CUSTOMER_GROUP_LIST: 'CustomerGroupList',
	CUSTOMER_GROUP_CREATE: 'CustomerGroupCreate',
	CUSTOMER_GROUP_EDIT: 'CustomerGroupEdit',
	SEARCH_SYNONYMS: 'SearchSynonyms',
	SEARCH_SYNONYMS_LIST: 'SearchSynonymsList',
	SEARCH_WEIGHTS: 'SearchWeights',
	SEARCH_WEIGHT_LIST: 'SearchWeightList',
	SEARCH_WEIGHT_CREATE: 'SearchWeightCreate',
	SEARCH_WEIGHT_EDIT: 'SearchWeightEdit',
	SEARCH_RESULT_CACHE: 'SearchResultCache',
	SEARCH_RESULT_CACHE_LIST: 'SearchResultCacheList',
	PAGES: 'Pages',
	PAGE_CREATE: 'PageCreate',
	PAGE_EDIT: 'PageEdit',
	HOMEPAGE: 'Homepage',
	SUB_HEADERS: 'SubHeaders',
	SUB_HEADER_HOMEPAGE: 'SubHeaderHomepage',
	SUB_HEADER_CART: 'SubHeaderCart',
	SUB_HEADER_STATIC_PAGE: 'SubHeaderStaticPage',
	WIDGET_LIST: 'WidgetList',
	BANNER: 'Banner',
	BANNER_HERO_LIST: 'BannerHeroList',
	BANNER_HERO_CREATE: 'BannerHeroCreate',
	BANNER_HERO_EDIT: 'BannerHeroEdit',
	BANNER_HIGHLIGHT_LIST: 'BannerHighlightList',
	BANNER_HIGHLIGHT_CREATE: 'BannerHighlightCreate',
	BANNER_HIGHLIGHT_EDIT: 'BannerHighlightEdit',
	BANNER_SMALL_LIST: 'BannerSmallList',
	BANNER_SMALL_CREATE: 'BannerSmallCreate',
	BANNER_SMALL_EDIT: 'BannerSmallEdit',
	BANNER_TEXT_LIST: 'BannerTextList',
	BANNER_TEXT_CREATE: 'BannerTextCreate',
	BANNER_TEXT_EDIT: 'BannerTextEdit',
	BANNER_PICTURE_THUMBNAIL_LIST: 'BannerPictureThumbnailList',
	BANNER_PICTURE_THUMBNAIL_CREATE: 'BannerPictureThumbnailCreate',
	BANNER_PICTURE_THUMBNAIL_EDIT: 'BannerPictureThumbnailEdit',
	MARKETING: 'Marketing',
	MARKETING_CAMPAIGN_LIST: 'MarketingCampaignList',
	MARKETING_CAMPAIGN_CREATE: 'MarketingCampaignCreate',
	MARKETING_CAMPAIGN_EDIT: 'MarketingCampaignEdit',
	MARKETING_CAMPAIGN_ATTRIBUTE_EDIT: 'MarketingCampaignAttributeEdit',
	PROMOTION: 'Promotion',
	PROMOTION_LIST: 'PromotionList',
	PROMOTION_CREATE: 'PromotionCreate',
	PROMOTION_EDIT: 'PromotionEdit',
	PRE_ORDERS: 'PreOrders',
	PRE_ORDER_LIST: 'PreOrderList',
	PRE_ORDER_CREATE: 'PreOrderCreate',
	PRE_ORDER_EDIT: 'PreOrderEdit',
	PRE_ORDER_PICKUP_AT_STORE_CREATE: 'PreOrderPickupAtStoreCreate',
	PRE_ORDER_PICKUP_AT_STORE_EDIT: 'PreOrderPickupAtStoreEdit',
	PRODUCT_FEED_LIST: 'ProductFeedList',
	PRODUCT_FEED_CREATE: 'ProductFeedCreate',
	PRODUCT_FEED_EDIT: 'ProductFeedEdit',
	FLASH_SALES: 'FlashSales',
	FLASH_SALE_LIST: 'FlashSaleList',
	FLASH_SALE_CREATE: 'FlashSaleCreate',
	FLASH_SALE_EDIT: 'FlashSaleEdit',
	MICROSITE: 'Microsite',
	MICROSITE_WIDGETS: 'MicrositeWidgets',
	MICROSITE_BANNER_HERO_LIST: 'MicrositeBannerHeroList',
	MICROSITE_BANNER_HERO_CREATE: 'MicrositeBannerHeroCreate',
	MICROSITE_BANNER_HERO_EDIT: 'MicrositeBannerHeroEdit',
	MICROSITE_BANNER_HIGHLIGHT_LIST: 'MicrositeBannerHighlightList',
	MICROSITE_BANNER_HIGHLIGHT_CREATE: 'MicrositeBannerHighlightCreate',
	MICROSITE_BANNER_HIGHLIGHT_EDIT: 'MicrositeBannerHighlightEdit',
	MICROSITE_BANNER_SHORTCUT_LIST: 'MicrositeBannerShortcutList',
	MICROSITE_BANNER_SHORTCUT_CREATE: 'MicrositeBannerShortcutCreate',
	MICROSITE_BANNER_SHORTCUT_EDIT: 'MicrositeBannerShortcutEdit',
	MICROSITE_BANNER_CONTENT_LIST: 'MicrositeBannerContentList',
	MICROSITE_BANNER_CONTENT_CREATE: 'MicrositeBannerContentCreate',
	MICROSITE_BANNER_CONTENT_EDIT: 'MicrositeBannerContentEdit',
	AUDIT_LOGS: 'AuditLogs',
	AUDIT_LOGS_LIST: 'AuditLogsList',
	AUDIT_LOG_DETAIL: 'AuditLogDetail',
	ABANDONED_CART: 'AbandonedCart',
	ABANDONED_CART_LIST: 'AbandonedCartList',
	BUNDLE_SET: 'BundleSet',
	BUNDLE_SET_LIST: 'BundleSetList',
	BUNDLE_SET_CREATE: 'BundleSetCreate',
	BUNDLE_SET_EDIT: 'BundleSetEdit',
	BACK_IN_STOCK_NOTIFICATIONS: 'BackInStockNotification',
	BACK_IN_STOCK_NOTIFICATIONS_LIST: 'BackInStockNotificationList',

	// Limit Product
	LIMIT_PRODUCT_PER_ACCOUNT_LIST: 'LimitProductList',
	LIMIT_PRODUCT_PER_ACCOUNT_CREATE: 'LimitProductCreate',
	LIMIT_PRODUCT_PER_ACCOUNT_EDIT: 'LimitProductEdit',

	// Regsiter
	REGISTER_SOCIAL: 'RegisterSocial',

	ON_SITE_MESSAGING: 'OnSiteMessaging',
	ON_SITE_MESSAGE_POPUP_LIST: 'OnSiteMessagePopupList',
	ON_SITE_MESSAGE_POPUP_CREATE: 'OnSiteMessagePopupCreate',
	ON_SITE_MESSAGE_POPUP_EDIT: 'OnSiteMessagePopupEdit',
	ON_SITE_MESSAGE_STRIP_LIST: 'OnSiteMessageStripList',
	ON_SITE_MESSAGE_STRIP_CREATE: 'OnSiteMessageStripCreate',
	ON_SITE_MESSAGE_STRIP_EDIT: 'OnSiteMessageStripEdit',
	ORDER_AUTO_COMPLETE: 'orderAutoComplete',
	FORBIDDEN: '403',
	PAGE_NOT_FOUND: '404',
	INDEX: 'Index',
	PAGE_SERVER_ERROR: '500',
};
